import { request } from "@/Api";
import { Button, Modal, Grid, Divider, Link} from "@mui/material";
import { useSelector } from "react-redux";
import { selectBidUpdate, selectProject, updateDetails } from "./bidSlice";
import React, { useEffect, useRef, useState } from "react";
import { TopSheetPreview } from "./TopSheetPreview";
import { updateGroup } from "./bidSlice";
import { useDispatch } from "react-redux";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useTheme } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { AllGroupAccordions, SwitchItem } from "./TopSheetConfiguratorComponents";
import { InfoInput, SendersInput } from "./TopSheetConfiguratorComponents";
import { SignerPopup } from "./TopSheetConfiguratorComponents";
import { Snackbar, Alert } from "@mui/material";
import { BID_STATUS_APPROVED } from "./constants";
import TopSheetConfiguratorLegalEntity from "./TopSheetConfiguratorLegalEntity";
import { downloadTopsheet } from "./TopsheetDownload";
import { selectIsMakers } from "@/auth/authSlice";

export function TopSheetConfigurator({ sendSocketMessage, requestFormOpen, setRequestFormOpen, open, close, submitRequestSignature }){
    const bid = useSelector(selectBidUpdate)
    const isMakers = useSelector(selectIsMakers)
    const bidBeforeConfig = useRef()
    const project = useSelector(selectProject)
    const theme = useTheme()
    const dispatch = useDispatch()
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))

    const [isResetting, setIsResetting] = useState(false)
    const [signatureRequested, setSignatureRequested] = useState(false)

    const closeRequestForm = () => {
        setRequestFormOpen(false)
    }

    const onInfoBlur = (field) => (event) => {
        if (bid.top_sheet_extra_info == event.target.value) {
            return
        }
        dispatch(updateDetails({...bid, [field]: event.target.value}))
        updateBidRequest({...bid, [field]: event.target.value})
    }

    const onSendersChange = (event, value) => {
        const top_sheet_sender_ids = value.map(sender=>sender.id)
        dispatch(updateDetails({
            ...bid,
            top_sheet_senders: value,
            top_sheet_sender_ids: top_sheet_sender_ids
        }))
        updateBidRequest({...bid, top_sheet_sender_ids: top_sheet_sender_ids})
    }

    const onSwitchChange = (field) => (event, value) => {
        dispatch(updateDetails({...bid, [field]: value}))
        updateBidRequest({...bid, [field]: value})
    }

    const updateBidRequest = (bidPayload) => {
        request.patch(`/bids/${bid.id}`, bidPayload)
        .then(()=>{
            sendSocketMessage({type: "update", bidId: bid.id})
        })
    }

    const resetTopSheet = () => {
        let groupsStack = Object.values(bidBeforeConfig.current.line_items_grouped)
        while (groupsStack.length > 0){
            const group = groupsStack.pop()
            groupsStack = groupsStack.concat(group.children)
            dispatch(updateGroup(group))
        }
        setIsResetting(true)
        updateTopSheet(bidBeforeConfig.current)
    }

    const updateTopSheet = (bid) => {
        dispatch(updateDetails(bid))
        request.patch(`/bids/${bid.id}/topsheet`, bid)
        .then(()=>{
            sendSocketMessage({type: "update", bidId: bid.id})
            setIsResetting(false)
        })
    }

    useEffect(()=>{
        if (!bid.id){
            return
        }
        bidBeforeConfig.current = {...bid}
        bidBeforeConfig.current.top_sheet_sender_ids = bid.top_sheet_senders.map((sender)=>{return sender.id})
    }, [open])

    if (!bid.id){
        return
    }

    return (
        <Modal open={open} onClose={close}>
            <div style={{display: "flex", height: "100%"}}>
                <SignerPopup 
                    bidId={bid.id}
                    open={requestFormOpen}
                    close={closeRequestForm}
                    setSignatureRequested={setSignatureRequested}
                />
                <Snackbar
                    open={signatureRequested}
                    autoHideDuration={5000}
                    onClose={(event, reason) => {setSignatureRequested(false)}}
                >
                    <Alert severity="success">Signature request successful!</Alert>
                </Snackbar>

                {isSmall ? null : 
                <div style={{height: "100vh", width: "100%"}}>
                     <div style={{height: "100vh", display: "flex", justifyContent: "center"}}> 
                        <div style={{
                            background: "white",
                            width: "60vh",
                            height: "90vh",
                            margin: "2vh",
                            padding: "4vh 4vh 2vh 6vh",
                            color: "black",
                            position: "absolute",
                            
                        }}>
                            {!project && "Top sheet preview not yet available for template"}
                        </div>
                        {project && <TopSheetPreview frontend />}
                    </div>
                </div>}

                <div style={{background: theme.palette.background.default, width: isSmall ? "100%" : 600, paddingLeft: 40, paddingRight: 40, overflowY: "scroll", paddingTop: 25}}>
                    {project && <>
                        <p>{project.name}</p>
                        <p style={{fontSize: 20, fontWeight: 600}}>Top Sheet Configurator</p>
                        <h4>Producers</h4>
                        <SendersInput
                            onChange={onSendersChange}
                            projectId={project.id}
                            value={bid.top_sheet_senders}
                        />
                        <Divider sx={{marginBottom: 4}}/>
                        <h4>Company Info</h4>
                        <TopSheetConfiguratorLegalEntity legalEntity={project.legal_entity}/>
                        <Divider sx={{marginBottom: 4, marginTop: 4}}/>
                    </>}

                    <h4>Extra Header Info</h4>
                    <InfoInput 
                        onBlur={onInfoBlur("top_sheet_extra_info")}
                        text={bid.top_sheet_extra_info}
                    />
                    <Divider sx={{marginTop: 4, marginBottom: 4}}/>

                    <h4>Section & Category Settings</h4>
                    <AllGroupAccordions updateTopSheet={updateTopSheet} bid={bid} />
                    <Divider sx={{marginTop: 4, marginBottom: 4}}/>

                    <h4>General Settings</h4>
                    {isMakers && <SwitchItem label="Liability Waiver" checked={bid.include_liability_waiver} onChange={onSwitchChange("include_liability_waiver")} />}
                    {isMakers && <SwitchItem label="Code of Conduct" checked={bid.include_code_of_conduct} onChange={onSwitchChange("include_code_of_conduct")} />}
                    <SwitchItem label="Decimals" checked={bid.include_decimals} onChange={onSwitchChange("include_decimals")} />
                    {isMakers && <SwitchItem label="COVID-19 Costs" checked={bid.include_covid_terms} onChange={onSwitchChange("include_covid_terms")} />}
                    <SwitchItem label="Include Billing Schedule" checked={bid.include_bill_schedule} onChange={onSwitchChange("include_bill_schedule")} />
                    <SwitchItem label="Zero Lines" checked={bid.include_zero_lines} onChange={onSwitchChange("include_zero_lines")} />
                    <SwitchItem label="Is Unilateral" checked={bid.is_unilateral} onChange={onSwitchChange("is_unilateral")} />
                    <Divider sx={{marginTop: 4, marginBottom: 4}}/>
                    

                    <div style={{background: theme.palette.background.default, textAlign: "center", position: "sticky", bottom: 0, padding: 20, alignItems: "center", margin: "auto"}}>
                        <Grid container spacing={1}>
                            <Grid md={12} item>
                                <Button sx={{color: "white"}} fullWidth variant="contained" onClick={close}>
                                    Go Back
                                </Button>
                            </Grid>
                            <Grid md={12} item>
                                {isResetting ? 
                                    <CircularProgress /> :
                                    <Button sx={{color: "black"}} fullWidth variant="contained" onClick={resetTopSheet} color="inherit">
                                        Reset Changes
                                    </Button>
                                }
                            </Grid>
                            {bid.status == BID_STATUS_APPROVED ?
                                <Grid md={12} item>
                                    <Button sx={{color: "white"}} fullWidth variant="contained" onClick={()=>{setRequestFormOpen(true)}}>
                                        Request Client Signature
                                    </Button>
                                </Grid> : null
                            }
                            <Grid md={12} item>
                                <Link onClick={()=>{downloadTopsheet(bid.name)}} sx={{textDecoration: "none", cursor: "pointer"}}>
                                    <Grid container alignItems={"center"} justifyContent={"center"}>
                                        <SaveAltIcon />
                                        &nbsp;&nbsp;
                                        <span>Download PDF</span>
                                    </Grid>
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </Modal>
    )
}