import { selectSchema, } from "@/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function BaseOrganizationComponents({children}){
    const navigate = useNavigate() // need this instance for this component to work
    const schema = useSelector(selectSchema)
    if (!(schema == "organization")){
        return null
    }
    return children
}