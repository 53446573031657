import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import { SearchBox } from "@/common/StyledComponents";
import AccountingBillsTable from "@/actuals/AccountingBillsTable"
import CsvExportButton from "@/common/CsvExportButton";

export default function AccountingBillsTab({accountingBills, projectCurrency, projectId, getActualLinesTotal}){
    useEffect(()=>{
        setAccountingBillsLocal(accountingBills)
    }, [accountingBills])

    const [ accountingBillsLocal, setAccountingBillsLocal ] = useState([])
    const handleAccountingBillsSearch = (event) => {
        const searchValue = event.target.value.toLowerCase()
        setAccountingBillsLocal(
            searchValue ? accountingBills.filter((bill=>bill.vendor_name.toLowerCase().includes(searchValue))) : accountingBills
        )
    }

    return (
        <div>
            <Grid container justifyContent={"space-between"} alignItems={"center"} paddingBottom={5}>
                <Grid item>
                    <p style={{margin: 0, paddingBottom: 20, paddingRight: 20}}>New Bills</p>
                </Grid>
                <Grid item>
                    <Grid container spacing={3} alignItems={"center"}>
                        <Grid item>
                            <CsvExportButton model="accounting-bill" filters={{project_id: projectId}} />
                        </Grid>
                        <Grid item>
                            <SearchBox handleSearch={handleAccountingBillsSearch}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AccountingBillsTable 
                projectCurrency={projectCurrency}
                accountingBills={accountingBillsLocal.filter(bill => getActualLinesTotal(bill.actual_lines) != bill.actual)}
            />
            <p style={{paddingTop: 30}}>Resolved Bills</p>
            <AccountingBillsTable
                resolved
                projectCurrency={projectCurrency}
                accountingBills={accountingBillsLocal.filter(bill => getActualLinesTotal(bill.actual_lines) == bill.actual)} 
            />
        </div>
    )
}
