import { CustomBox, Heading } from "@/common/StyledComponents";
import { Autocomplete, Grid, InputLabel, Modal, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSignedBids, updateLine } from "./actualSlice";
import { Button } from "@mui/material";
import { request } from "@/Api";
import { useDispatch } from "react-redux";

export function ActualLinePopup({ open, setOpen }){
    const [ actualLine, setActualLine ] = useState({})
    const dispatch = useDispatch()
    const signedBids = useSelector(selectSignedBids)
    const theme = useTheme()

    useEffect(()=>{
        if (signedBids.length > 0){
            setActualLine({...actualLine, "bid_id": signedBids[0].id})
        }
    }, [signedBids])

    const createActualLine = () => {
        request.post("actual-lines", actualLine)
        .then(response=>{
            dispatch(updateLine(response.data))
            setOpen(false)
            setActualLine({"bid_id": signedBids[0].id})
            document.documentElement.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
            })
        })
    }
    
    const handleInput = (field) => (event) => {
        setActualLine({...actualLine, [field]: event.target.value})
    }

    const handleAutocompleteChange = (field) => (event, newValue) => {
        setActualLine({...actualLine, [field]: newValue})
    }

    const handleCancel = () => {
        setOpen(false)
        setActualLine({})
    }

    return (
        <Modal open={open} onClose={()=>{setOpen(false)}}>
            <CustomBox style={{background: theme.palette.background.paper, width: 400}}>
                <Heading style={{paddingBottom: 20}}>Create an actual line</Heading>
                <InputLabel>Name</InputLabel>
                <TextField 
                    sx={{marginBottom: 2}}
                    fullWidth
                    value={actualLine.name || ""}
                    onInput={handleInput("name")}
                />
                <Grid container justifyContent={"right"} spacing={2}>
                    <Grid item><Button variant="contained" color="secondary" onClick={handleCancel}>Cancel</Button></Grid>
                    <Grid item><Button variant="contained" onClick={createActualLine}>Create</Button></Grid>
                </Grid>
            </CustomBox>
        </Modal>
    )
}