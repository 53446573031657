import { useSelector } from "react-redux";
import styled from 'styled-components'

import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid"
import { Link } from "@mui/material";

import { Heading } from "@/common/StyledComponents";
import { formattedCurrency } from "@/utils";
import { selectTotals } from "@/actuals/actualSlice"

const SmallGrey = styled.span`
    color: grey;
    font-size: 9px;
`

export default function ActualTotals({ projectId, name, projectCurrency }){
    const totals = useSelector(selectTotals)
    const theme = useTheme()
    
    const TotalGridItem = (props) => {
        const { label, amount, isPercentage, subText } = props
        return (
            <Grid item>
                <div style={{background: theme.palette.background.paper, padding: 8, borderRadius: 5, minWidth: 90}}>
                    <SmallGrey>{ label }</SmallGrey>
                    <br />
                    <span style={{margin: 1, fontSize: 14}}>
                        { isPercentage ? `${amount.toFixed(1)}%` : formattedCurrency(amount, projectCurrency) }
                    </span>
                    {subText ? <SmallGrey>{` ${subText}`}</SmallGrey> : null}
                </div>
            </Grid>
        )
    }
    return (
        <Grid container alignItems={"center"} justifyContent={"space-between"} marginBottom={5}>
            <Link href={`/projects/${projectId}`}>
                <Grid item><Heading>{name}</Heading></Grid>
            </Link>
            <Grid item>
                <Grid container spacing={2}>
                    <TotalGridItem item label="Total Quoted" amount={totals.bid_quoted || 0} />
                    <TotalGridItem item label="Budgeted Hard Cost" amount={totals.bid_hard_cost || 0} />
                    <TotalGridItem item label="Running Hard Costs" amount={totals.running_total || 0} />
                    <TotalGridItem item label="Actual Hard Costs" amount={totals.actual_total || 0} />
                    <TotalGridItem item label="Budgeted Internal Fees" amount={totals.bid_internal_budget || 0} />
                    <TotalGridItem item label="Actual Internal Fees" amount={totals.float_internal_actual || 0} />   
                    <TotalGridItem item label="Est. GP" amount={totals.bid_gp || 0} subText={totals.gross_profit_margin ? `(${(totals.gross_profit_margin*100).toFixed(1)}%)` : "(0%)"} />
                    <TotalGridItem item label="Actual GP" amount={totals.actual_profit || 0} subText={totals.actual_profit_margin ? `(${(totals.actual_profit_margin*100).toFixed(1)}%)` : "(0%)"} />
                </Grid>
            </Grid>
        </Grid>

    )
}