import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { request } from "@/Api";
import ActualizationPopup from "@/actuals/ActualizationPopup";
import AccountingBillsTab from "@/actuals/AccountingBillsTab";
import ActualLinesTab from "@/actuals/ActualLinesTab";
import ActualHeader from "@/actuals/ActualHeader";
import { updateTotals, updateLines, switchTab, selectCurrentTabId, updateSignedBids } from "@/actuals/actualSlice";
import ActualizationPopupDeallocate from "./ActualizationPopupDeallocate";

export default function Actuals(){
    const { projectId } = useParams()
    const [ project, setProject ] = useState(null)

    const dispatch = useDispatch()

    async function getProject(){
        return await request.get(`/projects/${projectId}/actuals`)
    } 
    const getAndSetProject = () => {
        getProject()
        .then((response) => {
            dispatch(updateTotals(response.data))
            dispatch(updateLines(response.data.actual_lines))
            dispatch(updateSignedBids(response.data.signed_bids))
            setProject(response.data)
        })
    }
    useEffect(()=>{
        getAndSetProject()
    }, [])

    const getActualLinesTotal = (actualLines) => {
        return actualLines.reduce((total, {actual}) => {
            return total + actual
        }, 0)
    }

    if (!project){
        return null
    }

    return (
        <div>
            <ActualHeader projectId={projectId} name={project.name} projectCurrency={project.currency}/>
            <TabsWrapper />
            <TabWrapper tabId={0}>
                <ActualLinesTab
                    projectCurrency={project.currency}
                    unmatchedBills={project.accounting_bills.filter(bill => {
                        return getActualLinesTotal(bill.actual_lines) != bill.actual
                    })}
                    getProject={getProject}
                    getActualLinesTotal={getActualLinesTotal}
                />
            </TabWrapper>

            <TabWrapper tabId={1}>
                <AccountingBillsTab
                    projectCurrency={project.currency}
                    accountingBills={project.accounting_bills}
                    projectId={projectId}
                    getActualLinesTotal={getActualLinesTotal}
                />
            </TabWrapper>

            <ActualizationPopup
                getAndSetProject={getAndSetProject}
                projectCurrency={project.currency}
                getActualLinesTotal={getActualLinesTotal}
            />

            <ActualizationPopupDeallocate
                getAndSetProject={getAndSetProject}
            />
        </div>
    )
}

function TabWrapper({children, tabId}){
    const selectedTabId = useSelector(selectCurrentTabId)
    return (
        <div hidden={tabId != selectedTabId}>
            {children}
        </div>
    )
}

function TabsWrapper(){
    const selectedTabId = useSelector(selectCurrentTabId)
    const dispatch = useDispatch()
    const handleTabChange = (event, newValue) => {
        dispatch(switchTab(newValue))
    } 
    return (
        <Tabs value={selectedTabId} onChange={handleTabChange} sx={{borderBottom: 1, color: 'grey', marginBottom: 5}}>
            <Tab label="Actuals"/>
            <Tab label="Bills"/>
        </Tabs>
    )
}