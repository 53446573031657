import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from "@mui/material/IconButton";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SellIcon from '@mui/icons-material/Sell';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CompareIcon from '@mui/icons-material/Compare';

import InlineRename from "@/bid/InlineRename";
import BidTemplatePopup from "@/bids/BidTemplatePopup";
import BidCopyPopup from '@/bids/BidCopyPopup';
import { selectIsSigned, selectBidUpdate, updateName, updateIsChangeOrder } from "@/bid/bidSlice";
import { downloadFile } from "@/utils";
import { request } from "@/Api";
import StrategyComponents from '@/common/StrategyComponents';
import { ACCOUNTING_STRATEGY } from '@/constants';

export default function BidTitle({ bidId }) {
    const [ menuAnchorEl, setMenuAnchorEl] = useState(null)
    const [ showBidRename, setShowBidRename ] = useState(false)
    const [ bidTemplatePopupOpen, setBidTemplatePopupOpen ] = useState(false)
    const [ bidToCopy, setBidToCopy ] = useState(null)

    const isSigned = useSelector(selectIsSigned)
    const bid = useSelector(selectBidUpdate)
    const dispatch = useDispatch()

    const handleMenuOpen = (event) => {
        event.stopPropagation()
        setMenuAnchorEl(event.currentTarget)
    }

    const handleMenuClose = (event) => {
        event.stopPropagation()
        setMenuAnchorEl(null)
    }

    const handleBidRename = (event) => {
        handleMenuClose(event)
        setShowBidRename(true)
    }

    const renameBid = (value) => {
        request.patch(`/bids/${bidId}`, {name: value})
        .then(()=>{
            setShowBidRename(false)
            dispatch(updateName(value))
        })
    }
    
    const closeRename = (event) => {
        event.stopPropagation()
        setShowBidRename(false)
    }

    const handleCsvExport = (event) => {
        handleMenuClose(event)
        downloadFile(`bids/${bidId}/csv-export`)
    }

    const handleCopy = (event) => {
        handleMenuClose(event)
        setBidToCopy(bid)
    }

    const handleTemplate = (event) => {
        handleMenuClose(event)
        setBidTemplatePopupOpen(true)
    }

    return showBidRename ? (
        <InlineRename
            defaultName={bid.name}
            close={closeRename}
            rename={renameBid}
        />
    ) : (
        <>
            <span>{bid.name}</span>
            <IconButton sx={{marginLeft: "6px"}} onClick={handleMenuOpen}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleBidRename}>
                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCsvExport}>
                    <ListItemIcon><FileDownloadIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Export as CSV</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCopy}>
                    <ListItemIcon><ContentCopyIcon fontSize="small"/></ListItemIcon>
                    <ListItemText>Duplicate {bid.is_change_order ? 'Change Order' : 'Bid Sheet'}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleTemplate}>
                    <ListItemIcon><SellIcon fontSize="small"/></ListItemIcon>
                    <ListItemText>Save as Template</ListItemText>
                </MenuItem>
                {/* TODO add ability to change bid type */}
                <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                    <a href={`/projects/${bid.project_id}/actuals`} style={{textDecoration: "none", color: "inherit"}}>
                        {isSigned ?
                        <MenuItem>
                            <ListItemIcon><FactCheckIcon fontSize="small"/></ListItemIcon>
                            <ListItemText>Actualize</ListItemText>
                        </MenuItem> : null}
                    </a>
                </StrategyComponents>
            </Menu>

            <BidTemplatePopup 
                bidId={bidId}
                bidTemplatePopupOpen={bidTemplatePopupOpen}
                setBidTemplatePopupOpen={setBidTemplatePopupOpen}
            />

            <BidCopyPopup
                bid={bidToCopy}
                setBid={setBidToCopy}
            />
        </>
    )
}
