import { useDispatch, useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

import { FormattedCurrency, TableCellSmall } from "@/common/StyledComponents";
import { addLineToAllocate, removeLineToAllocate, selectLine, selectLineToAllocate } from "@/actuals/actualSlice";

function ActualAssignRow({actualLineId, projectCurrency}){
    const line = useSelector(state => selectLine(state, actualLineId))
    const lineToAllocate = useSelector(state => selectLineToAllocate(state, line.id))
    const dispatch = useDispatch()
    const handleToggle = (line) => (event, isChecked) => {
        if (isChecked){
            dispatch(addLineToAllocate({...line, actual: 0, actual_line_id: line.id}))
        }else{
            dispatch(removeLineToAllocate(line))
        }
    }
    return (
        <TableRow key={line.id}>
            <TableCellSmall>
                <Checkbox 
                    checked={lineToAllocate != undefined}
                    size="small" onChange={handleToggle(line)}
                />
            </TableCellSmall>
            <TableCellSmall>{line.name}</TableCellSmall>
            <TableCellSmall>{line.section}</TableCellSmall>
            <TableCellSmall>{line.category}</TableCellSmall>
            <TableCellSmall align={"right"}>
                <FormattedCurrency currency={line.vendor_currency} number={line.budget}/>
            </TableCellSmall>
            <TableCellSmall align={"right"}>
                <FormattedCurrency currency={projectCurrency} number={line.actual_project}/>
            </TableCellSmall>
        </TableRow>
    )
}

export default function ActualLinesAssignTable(props){
    const { lineIds, projectCurrency } = props
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellSmall></TableCellSmall>
                        <TableCellSmall>Item</TableCellSmall>
                        <TableCellSmall>Section</TableCellSmall>
                        <TableCellSmall>Category</TableCellSmall>
                        <TableCellSmall align={"right"}>Vendor Budget</TableCellSmall>
                        <TableCellSmall align={"right"}>Project Actual</TableCellSmall>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lineIds.map((id)=>
                        <ActualAssignRow
                            key={id}
                            actualLineId={id}
                            projectCurrency={projectCurrency}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}