import { useState } from "react"
import { useDispatch } from "react-redux"

import styled from "styled-components"

import { useTheme, Button, CircularProgress, Grid, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'

import { AccountSectionTitle } from "@/account/StyledComponents"
import { OrgImage } from "@/account/CommonComponents"
import ConfirmationDialog from "@/common/ConfirmationDialog"
import OrganizationDialog from "@/account/OrganizationDialog"
import { request } from "@/Api"
import { setToken } from "@/auth/authSlice"


const Organization = styled.div`
    margin: 12px auto; 
    padding: 16px 20px;
    border-radius: 14px;
    background-color: ${props => props.backgroundColor};
    display: flex;
`
const OrganizationName = styled.h3`
    margin: 0 0 6px 0;
    font-weight: 400;
    font-size: 16px;
`
const OrganizationItalic = styled.p`
    margin: 0;
    font-style: italic;
    font-size: 14px;
`
const AddOrganization = styled(Organization)`
    background-color: transparent;
    border-width: 2px;
    border-style: dashed;
    border-color: ${props => props.borderColor};
    color: ${props => props.color};
    padding: 28px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border-style: solid;
    }
`


export default function OrganizationsSection({ data, setData, setSaved, setError }) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [ selectedOrg, setSelectedOrg ] = useState(null)
    const [ pauseConfirm, setPauseConfirm ] = useState(false)
    const [ closeConfirm, setCloseConfirm ] = useState(false)
    const [ leaveConfirm, setLeaveConfirm ] = useState(false)
    const [ editing, setEditing ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const showCreateOrg = data.every(org => !org.is_owner)

    const onPause = (org) => () => {
        setSelectedOrg(org)
        setPauseConfirm(true)
    }

    const pause = () => {
        setLoading(true)

        request.post(`organization/${selectedOrg.id}/pause`).then(response => {
            setData(data.map(cur => cur.id == response.data.id ? {...response.data} : cur))
            refreshToken()
        }).catch(error => {
            setError("Failed to pause workspace. Please contact support at central@makers.to")
        }).finally(() => {
            setLoading(false)
        })
    }

    const onReactivate = (org) => () => {
        setLoading(true)

        request.delete(`organization/${org.id}/pause`).then(response => {
            setData(data.map(cur => cur.id == response.data.id ? {...response.data} : cur))
            refreshToken()
        }).catch(error => {
            setError("Failed to reactivate workspace. Please contact support at central@makers.to")
        }).finally(() => {
            setLoading(false)
        })
    }

    const onClose = (org) => () => {
        setSelectedOrg(org)
        setCloseConfirm(true)
    }

    const close = () => {
        setLoading(true)

        request.delete(`organization/${selectedOrg.id}`).then(response => {
            setData(data.filter(org => org.id != selectedOrg.id))
            refreshToken()
        }).catch(error => {
            if (error.response.status == 425) {
                setError("Please wait a minute and try again.")
            } else {
                setError("Failed to close workspace. Please contact support at central@makers.to")
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const onLeave = (org) => () => {
        setSelectedOrg(org)
        setLeaveConfirm(true)
    }

    const leave = () => {
        setLoading(true)

        request.delete(`organization/${selectedOrg.id}`).then(response => {
            setData(data.filter(org => org.id != response.data.id))
            refreshToken()
        }).catch(error => {
            setError("Failed to leave workspace. Please contact support at central@makers.to")
        }).finally(() => {
            setLoading(false)
        })
    }

    const onEdit = (org) => () => {
        setSelectedOrg(org)
        setEditing(true)
    }

    const edit = (newOrg) => {
        setData(data.map(org => org.id == newOrg.id ? newOrg : org))
        setSaved(true)
        refreshToken()
    }

    const add = (newOrg) => {
        setData([...data, newOrg])
        setSaved(true)
        refreshToken()
    }

    const cancelEdit = () => {
        setSelectedOrg(null)
        setEditing(false)
    }

    const onAdd = () => {
        setSelectedOrg(null)
        setEditing(true)
    }
    
    const refreshToken = () => {
        request.post(`refresh-token`, {}).then(response => {
            dispatch(setToken(response.data))
        })
    }

    return (
        <Grid item>
            <AccountSectionTitle>Workspaces</AccountSectionTitle>

            {loading ? (
                <CircularProgress sx={{display: "block", margin: "48px auto"}} />
            ) : data.map(org => (
                <Organization key={org.id} backgroundColor={theme.palette.background.paper}>
                    <Grid container alignItems="center">
                        <Grid item container xs={3} alignItems="center" spacing={2}>
                            <Grid item>
                                <OrgImage image={org.logo} size={40} />
                            </Grid>
                            <Grid item>
                                <OrganizationName>{org.name}</OrganizationName>
                                <OrganizationItalic>{`${org.member_count} member${org.member_count != 1 ? "s" : ""}`}</OrganizationItalic>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            {org.subscription || ""}
                        </Grid>
                        <Grid item xs={3}>
                            <OrganizationItalic>
                                {org.is_owner ? "Owner" : "Member"}
                            </OrganizationItalic>
                        </Grid>
                        <Grid item xs={3} container spacing={2} justifyContent="flex-end">
                        {org.is_owner ? (
                            <>
                                {org.paused ? (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={onReactivate(org)}
                                        >
                                            Reactivate
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={onPause(org)}
                                        >
                                            Pause
                                        </Button>
                                    </Grid>
                                )}
                                
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={onClose(org)}
                                    >
                                        Close
                                    </Button>
                                </Grid>

                               
                            </>
                        ) : org.is_makers ? null : (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onLeave(org)}
                            >
                                Leave
                            </Button>
                        )}
                        </Grid>
                        <Grid item xs={1} textAlign="center">
                            {org.is_owner && (
                                <IconButton
                                    onClick={onEdit(org)}
                                    disabled={org.paused}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Organization>
            ))}

            {showCreateOrg && (
                <AddOrganization
                    onClick={onAdd}
                    borderColor={theme.palette.background.paper}
                    color={theme.palette.text.secondary}
                >
                    + Add New
                </AddOrganization>
            )}

            <OrganizationDialog
                open={editing}
                handleClose={cancelEdit}
                data={selectedOrg}
                setData={selectedOrg ? edit : add}
                setError={setError}
            />
                  
            <ConfirmationDialog
                open={pauseConfirm}
                closeDialog={() => setPauseConfirm(false)}
                title="Are you sure?"
                callback={pause}
            >
                {`This will temporarily pause your "${selectedOrg?.name}" account and any associated billings. You will lose access to your data but it will still be retained. Reactivate your account to restore access.`}
            </ConfirmationDialog>

            <ConfirmationDialog
                open={closeConfirm}
                closeDialog={() => setCloseConfirm(false)}
                title="Are you sure?"
                callback={close}
            >
                {`This will remove all data from the workspace "${selectedOrg?.name}" and is irreversible. Note that this affects all users that are part of this workspace.`}
            </ConfirmationDialog>

            <ConfirmationDialog
                open={leaveConfirm}
                closeDialog={() => setLeaveConfirm(false)}
                title="Are you sure?"
                callback={leave}
            >
                {`You will no longer have access to any data on the workspace "${selectedOrg?.name}".`}
            </ConfirmationDialog>
        </Grid>
    )
}
