import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { selectIsLoggedIn, selectHasPermission, selectIsSuperAdmin } from "@/auth/authSlice"
import { PageMessage } from "@/common/StyledComponents"

export default function ProtectedContainer({ container, permission, requireSuperAdmin }){
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const isSuperAdmin = useSelector(selectIsSuperAdmin)
    const hasPermission = useSelector(state => selectHasPermission(state, permission))

    if (!isLoggedIn){
        localStorage.setItem("savedPath", window.location.pathname)
        return (<Navigate to={"/login"}></Navigate>)
    }

    if ((permission && !hasPermission) || (requireSuperAdmin && !isSuperAdmin)) {
        return (
            <PageMessage>You do not have permission to view this page.</PageMessage>
        )
    }

    return ( <>{container}</> )
}
