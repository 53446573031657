import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { request } from "@/Api";
import { formattedCurrency } from "@/utils";
import { FormattedCurrency, FlexRow, FlexCell } from "@/common/StyledComponents";
import SheetInputField from '@/bid/SheetInputField';
import { selectLine, updateTotals, updateLine } from "@/actuals/actualSlice";

export default function ActualLineRow({ actualLineId, projectCurrency, getProject }){
    const [ actualLineLocal, setActualLineLocal ] = useState({})
    const actualLine = useSelector(state => selectLine(state, actualLineId))
    const dispatch = useDispatch()

    useEffect(()=>{
        setActualLineLocal(actualLine)
    }, [actualLine])

    const handleBlur = (field) => (event) => {
        const value = event.target.value
        if (value == actualLine[field]){
            return
        }

        request.put(`actual-lines/${actualLine.id}`, {...actualLineLocal, [field]: value})
        .then((response)=>{
            dispatch(updateLine(response.data))

            getProject().then((response) => {
                dispatch(updateTotals(response.data))
            })
        })
    }

    const handleInput = (field) => (event) => {
        let value = event.target.value
        if (event.target.type == "number") {
            value = parseFloat(value) || 0
        }
        setActualLineLocal({...actualLineLocal, [field]: value})
    }

    if (!actualLine){
        return null
    }

    return(
        <FlexRow padding="0 0 16px 0">
            <FlexCell>{actualLineLocal.name}</FlexCell>
            <FlexCell>{actualLineLocal.line_item_id ? actualLineLocal.section : "n/a"}</FlexCell>
            <FlexCell>{actualLineLocal.line_item_id ? actualLineLocal.category : "n/a"}</FlexCell>
            <FlexCell grow={2} minWidth={400}>
                <SheetInputField 
                    value={actualLineLocal.notes || ""}
                    onChange={handleInput("notes")}
                    onBlur={handleBlur("notes")}
                />
            </FlexCell>
            <FlexCell align={"right"} padding="0 12px 0 0">
                {actualLineLocal.line_item_id ? 
                <FormattedCurrency currency={actualLineLocal.vendor_currency} number={actualLineLocal.budget} noMinWidth />
                : "n/a"}
            </FlexCell>
            <FlexCell align={"right"} minWidth={90}>
                <SheetInputField 
                    type="number"
                    value={actualLineLocal.running || 0}
                    onChange={handleInput("running")}
                    onBlur={handleBlur("running")}
                    formatter={(value) => formattedCurrency(value, actualLineLocal.vendor_currency, true)}
                    error={(actualLineLocal.running > actualLineLocal.budget && actualLineLocal.line_item_id)}
                />
            </FlexCell>
            <FlexCell align={"right"}>
                <FormattedCurrency currency={actualLineLocal.line_item_id ? actualLineLocal.vendor_currency : projectCurrency} number={actualLineLocal.actual} noMinWidth />
            </FlexCell>
            <FlexCell align={"right"}>
                <FormattedCurrency currency={projectCurrency} number={actualLineLocal.actual_project} noMinWidth />
            </FlexCell>
        </FlexRow>
    )
}