import { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTheme, Button, IconButton } from '@mui/material'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Unstable_Grid2'
import InputLabel from '@mui/material/InputLabel'
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from "@mui/material/Snackbar"
import Link from '@mui/material/Link';

import BackupIcon from '@mui/icons-material/Backup';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { request } from "@/Api"
import { Title } from "@/common/StyledComponents"
import { setError } from '@/nav/navSlice'
import { selectAccountId } from '@/auth/authSlice'

export default function FilesUploader({files, modelType, modelId, disabled, onUploadedCallback, title}) {
    const fileHolder = useRef()
    const theme = useTheme()
    const dispatch = useDispatch()
    
    const [filesLocal, setFilesLocal] = useState([])
    const [isDropOver, setIsDropOver] = useState(false)
    const [isFilesUploading, setIsFilesUploading] = useState(false)
    const [showInvalidFileAlert, setShowInvalidFileAlert] = useState(false)

    const accountId = useSelector(selectAccountId)

    useEffect(()=>{
        setFilesLocal([...files])
    }, [files])

    const uploadButtonClickHandler = () => {
        fileHolder.current.click()
    }

    const onUploadHandler = (e) => {
        e.preventDefault()
        if (disabled) {
            return
        }

        setIsDropOver(false)
        setIsFilesUploading(true)

        let dataTransfer = new DataTransfer();
        let newFiles = []
        let promises = []

        if (!e.dataTransfer){
            for (var i in fileHolder.current.files){
                if(fileHolder.current.files[i].constructor.name == "File"){
                    dataTransfer.items.add(fileHolder.current.files[i])
                }
            }
        }else{
            dataTransfer = e.dataTransfer
        }

        for (i in dataTransfer.items){
            if(dataTransfer.items[i].kind === "file" && dataTransfer.items[i].type != "application/pdf"){
                setIsFilesUploading(false)
                setShowInvalidFileAlert(true)
                return
            }
        }

        ;[...dataTransfer.items].forEach((item, i) => {
        if (item.kind === "file") {
            const file = item.getAsFile()
            const formData = new FormData()
            formData.append("file", file, file.name)
            formData.append("model_type", modelType)
            formData.append("model_instance_id", modelId)
            promises.push(request.post(`/files`, formData)
            .then((response)=>{
                newFiles.push(response.data)
            }))
        }});

        Promise.all(promises).then(()=>{
            setIsFilesUploading(false)
            setFilesLocal(filesLocal.concat(newFiles))
            if(onUploadedCallback){
                onUploadedCallback(newFiles)
            }
        })
        .catch((error)=>{
            dispatch(setError({ message: error.response.data.detail }))
            setIsFilesUploading(false)
        })
    }

    const onDropOverHandler = (e) => {
        e.preventDefault();
        if (disabled) {
            return
        }
        setIsDropOver(true)
    }
    const onDropLeaveHandler = (e) => {
        if (disabled) {
            return
        }
        setIsDropOver(false)
    }

    const onFileDeleteHandler = (fileId) => (e) => {
        request.delete(`files/${fileId}`)
        .then(()=>{
            setFilesLocal(filesLocal.filter((file)=>file.id != fileId))
        })
    }

    const FileItem = (props) => {
        const {file} = props
        return(
            <Grid container sx={{borderRadius:1, background: "#ffffff22", margin: 1, justifyContent:"space-between"}}>
                <Grid container alignItems={"center"}>
                    <Grid padding={0}>
                        <PictureAsPdfIcon sx={{paddingRight: 2}} />
                    </Grid>
                    <Grid padding={0}>
                        <InputLabel>
                            <Link target={"_blank"} href={file.url}>{file.name}</Link>
                        </InputLabel>
                    </Grid>
                </Grid>
                {disabled ? null : <IconButton onClick={onFileDeleteHandler(file.id)}>
                    {accountId == file.account_id ? <DeleteForeverIcon /> : null}
                </IconButton>}
            </Grid>
        )
    }

    return (
        <>
            <Title bold>{title || "Signed Documents"}</Title>
            <Grid
                padding={2}
                marginTop={2.5}
                textAlign={"center"}
                borderRadius={"5px"}
                border={isDropOver ? `${theme.palette.text.primary} 0.8px solid` : `${theme.palette.text.secondary} 0.8px ${disabled ? "solid" : "dashed"}`}
            >
                <div 
                    onDragLeave={onDropLeaveHandler} 
                    onDragOver={onDropOverHandler} 
                    onDrop={onUploadHandler}
                >
                    {filesLocal.length > 0 ? filesLocal.map((file)=>{
                        return(
                            <FileItem key={file.id} file={file} />
                        )
                    }):
                        <>
                            {disabled ? 
                                <InputLabel>None</InputLabel>
                                :
                            isFilesUploading ?
                                <InputLabel>Uploading your file(s) ... wait a moment</InputLabel>
                                :
                                <InputLabel>Drag & drop the signed client documents (must be PDF)</InputLabel>
                            }
                        </>
                    }

                    {disabled ? null : isFilesUploading ?
                        <CircularProgress/>
                        :
                        <Button onClick={uploadButtonClickHandler}>
                            <BackupIcon sx={{paddingRight: 1}} />
                            Upload
                        </Button>
                    }

                </div>
            </Grid>
            <input accept="application/pdf" ref={fileHolder} style={{display: "none"}} type="file" id="files" multiple onChange={onUploadHandler} />
            <Snackbar
                open={showInvalidFileAlert}
                autoHideDuration={2000}
                onClose={(event, reason) => {setShowInvalidFileAlert(false)}}
            >
                <Alert severity="error">Only PDF files are accepted</Alert>
            </Snackbar>
        </>
    )
}