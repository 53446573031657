import { useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";

import { SearchBox } from "@/common/StyledComponents";
import AccountingBillsTable from "@/actuals/AccountingBillsTable"
import ActualLinesTable from "@/actuals/ActualLinesTable";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Button, IconButton, useTheme } from "@mui/material";
import { ActualLinePopup } from "./ActualLinePopup";
import CsvExportButton from "@/common/CsvExportButton";
import { useParams } from "react-router-dom";

export default function ActualLinesTab({unmatchedBills, projectCurrency, getProject}) {
    const { projectId } = useParams()
    const theme = useTheme()
    const [ searchValue, setSearchValue ] = useState("")
    const [ actualLinePopupOpen, setActualLinePopupOpen ] = useState(false)

    const handleActualLinesSearch = (event) => {
        setSearchValue(event.target.value.toLowerCase())
    }

    return(
        <div style={{position: "relative"}}>
            <Paper sx={{padding: 3, marginBottom: 10}}>
                <Grid container>
                    <Grid item><p style={{margin: 0, paddingBottom: 20, paddingRight: 20}}>Unmatched bills</p></Grid>
                    <Grid item><Badge badgeContent={unmatchedBills.length} color="primary"/></Grid>
                </Grid>
                <AccountingBillsTable
                    projectCurrency={projectCurrency}
                    accountingBills={unmatchedBills}
                />
            </Paper>
            <Grid 
                container justifyContent={"space-between"} 
                alignItems={"center"} paddingBottom={3} paddingTop={3} 
                top={0} position={"sticky"} zIndex={1}
                sx={{
                    background: theme.palette.background.default
                }}
            >
                <Grid item>
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid item>
                            <Button variant="outlined" color={theme.palette.mode=="dark" ? "secondary" : "info"} endIcon={<AddCircleRoundedIcon />} onClick={()=>{setActualLinePopupOpen(true)}}>
                                Add new line item
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <SearchBox handleSearch={handleActualLinesSearch}/>
                </Grid>
            </Grid>
            <ActualLinesTable
                searchValue={searchValue}
                projectCurrency={projectCurrency}
                getProject={getProject}
            />
            <Grid item>
                <CsvExportButton filters={{project_id: projectId}} model={"actual-line"}/>
            </Grid>
            <ActualLinePopup 
                open={actualLinePopupOpen}
                setOpen={setActualLinePopupOpen}
            />
        </div>
    )
}