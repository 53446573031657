import { selectIsComplete, selectIsEnterprise, selectToken } from "@/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function EnterpriseComponents({children}){
    const navigate = useNavigate() // need this instance for this component to work
    const token = useSelector(selectToken)
    const isComplete = useSelector(selectIsComplete)
    const isEnterprise = useSelector(selectIsEnterprise)
    
    if (!token || !isComplete || !isEnterprise){
        return null
    }
    return children
}