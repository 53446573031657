import { createSelector, createSlice } from '@reduxjs/toolkit'

const defaultWorkingBill = {
    open: false,
    line_items: [],
    currency: "CAD"
}

export const actualSlice = createSlice({
    name: "actual",
    initialState: {
        totals: {
            client_budget: 0,
            running_total: 0,
            actual_total: 0,
            actual_profit: 0,
            gross_profit_margin: 0,
            actual_profit_margin: 0,
            bid_quoted: 0,
            bid_hard_cost: 0,
            bid_gp: 0,
            bid_internal_budget: 0,
            float_internal_actual: 0,
            accounting_rev: 0,
            accounting_cost: 0
        },
        workingBill: {...defaultWorkingBill},
        unassigningBill: null,
        currentTabId: 0,
        isAllocating: false,
        lineIds: [],
        lines: {
            // lineId: {
            //     ...lineData
            // },
        },
        linesToAllocate: [
            // ...actualLine
            // actual_line_id: int
        ],
        signedBids: []
    },

    reducers: {
        updateTotals: (state, action) => {
            const {
                client_budget, 
                running_total, 
                actual_total,
                actual_profit,
                gross_profit_margin,
                actual_profit_margin,
                bid_quoted,
                bid_hard_cost,
                bid_gp,
                bid_internal_budget,
                float_internal_actual,
                accounting_rev,
                accounting_cost
            } = action.payload
            state.totals = {
                client_budget, 
                running_total, 
                actual_total,
                actual_profit,
                gross_profit_margin,
                actual_profit_margin,
                bid_quoted,
                bid_hard_cost,
                bid_gp,
                bid_internal_budget,
                float_internal_actual,
                accounting_rev,
                accounting_cost
            }
        },
        updateLines: (state, action) => {
            state.lineIds = action.payload.map(line => line.id)
            state.lines = action.payload.reduce((o, line) => ({...o, [line.id]: {...line}}), {})
        },
        updateLine: (state, action) => {
            state.lines[action.payload.id] = action.payload
        },
        assignBill: (state, action) => {
            state.workingBill = { // WORKING BILL'S ALLOWCATION LINE INPUT SHOULD UPDATE THIS STATE
                open: true,
                ...action.payload
            }
        },
        unassignBill: (state, action) => {
            state.unassigningBill = {
                open: true,
                ...action.payload
            }
        },
        cancelAssignBill: (state) => {
            state.workingBill = {...defaultWorkingBill}
        },
        cancelUnassignBill: (state) => {
            state.unassigningBill = null
        },
        allocateActualLines: (state, action) => {
            // update totals
            // identify actual lines allocated amount to update
            // identify bills to move
        },
        setLinesToAllocate: (state, action) => {
            state.linesToAllocate = action.payload
        },
        updateLineToAllocateActual: (state, action) => {
            const {lineId, actual} = action.payload
            state.linesToAllocate = state.linesToAllocate.map((line) => lineId == line.id ? {...line, actual: actual} : line)
        },
        addLineToAllocate: (state, action) => {
            state.linesToAllocate.push(action.payload)
        },
        removeLineToAllocate: (state, action) => {
            state.linesToAllocate = state.linesToAllocate.filter(line => line.id != action.payload.id)
        },
        switchTab: (state, action) => {
            state.currentTabId = action.payload
        },
        allocate: (state, action) => {
            state.isAllocating = action.payload
        },
        distributeRemaining: (state) => {
            const numLinesToDistribute = state.linesToAllocate.filter((line)=>line.actual == 0).length
            const allocateTotal = state.linesToAllocate.reduce((total, line)=>total + line.actual, 0)
            const portion = (state.workingBill.actual - allocateTotal) / numLinesToDistribute
            state.linesToAllocate = state.linesToAllocate.map(line => line.actual == 0 ? {...line, actual: portion} : line)
        },
        updateSignedBids: (state, action) => {
            state.signedBids = action.payload
        }
    }
})

export const {
    updateTotals, updateLines, assignBill, unassignBill, allocateActualLines, cancelAssignBill, cancelUnassignBill, updateLine,
    addLineToAllocate, removeLineToAllocate, switchTab, allocate, setLinesToAllocate,
    updateLineToAllocateActual, distributeRemaining, updateSignedBids
} = actualSlice.actions

export const selectTotals = (state) => state.actual.totals
export const selectLineIds = (state) => state.actual.lineIds
export const selectLines = (state) => state.actual.lines
export const selectLine = (state, lineId) => state.actual.lines[lineId]
export const selectWorkingBill = (state) => state.actual.workingBill
export const selectUnassigningBill = (state) => state.actual.unassigningBill
export const selectLinesToAllocate = (state) => state.actual.linesToAllocate
export const selectLineToAllocate = (state, lineId) => state.actual.linesToAllocate.find(line => lineId == line.id)
export const selectSignedBids = (state) => state.actual.signedBids

export const selectSearchLineIds = createSelector(
    [selectLines, (state, searchValue) => searchValue], (lines, searchValue) => {
        const matchingLines = searchValue ?
            Object.values(lines).filter(line => line.name.toLowerCase().includes(searchValue)) :
            Object.values(lines)
        return matchingLines.map(line => line.id)
    }
)

export const selectAllocateTotal = createSelector(
    [selectLinesToAllocate], (lines) => {
        return lines.reduce((total, line)=>total + line.actual, 0)
    }
)
export const selectAllocateLineCount = createSelector(
    [selectLinesToAllocate], (lines) => {
        return lines.length
    }
)
export const selectZeroAllocateLineCount = createSelector(
    [selectLinesToAllocate], (lines) => {
        return lines.reduce((count, line)=>line.actual == 0 ? count + 1 : count, 0)
    }
)

export const selectCurrentTabId = (state) => state.actual.currentTabId
export const selectIsAllocating = (state) => state.actual.isAllocating

export default actualSlice.reducer