import { useDispatch } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';

import { TableCellSmall } from "@/common/StyledComponents";
import { FormattedCurrency } from "@/common/StyledComponents";
import { assignBill, unassignBill } from "@/actuals/actualSlice";
import { Grid } from '@mui/material';
import { request } from '@/Api';

export default function AccountingBillsTable({accountingBills, projectCurrency, resolved}){
    const dispatch = useDispatch()

    return(
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellSmall>Vendor</TableCellSmall>
                        <TableCellSmall>Invoice no.</TableCellSmall>
                        <TableCellSmall>Date</TableCellSmall>
                        {<TableCellSmall>Notes</TableCellSmall>}
                        <TableCellSmall align={"right"}>Vendor Total</TableCellSmall>
                        <TableCellSmall align={"right"}>Projects Total</TableCellSmall>
                        <TableCellSmall></TableCellSmall>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accountingBills.map((bill)=>
                        <TableRow key={bill.id}>
                            <TableCellSmall width={60}>{bill.vendor_name}</TableCellSmall>
                            <TableCellSmall width={60}>{bill.accounting_bill_number}</TableCellSmall>
                            <TableCellSmall width={60}>{bill.invoice_date}</TableCellSmall>
                            <TableCellSmall width={250}>{bill.description}</TableCellSmall>
                            <TableCellSmall width={60} align={"right"}>
                                <FormattedCurrency currency={bill.currency} number={bill.actual} />
                            </TableCellSmall>
                            <TableCellSmall width={60} align={"right"}>
                                <FormattedCurrency currency={projectCurrency} number={bill.actual_project} />
                            </TableCellSmall>
                            <TableCellSmall width={30} align={"right"}>
                                <Grid container>
                                    <Grid item md={6}>
                                        <IconButton onClick={()=>{dispatch(assignBill(bill))}}><MoreVertIcon/></IconButton>
                                    </Grid>
                                    {resolved && <Grid item md={6}>
                                        <IconButton onClick={()=>{dispatch(unassignBill(bill))}}><AssignmentReturnOutlinedIcon/></IconButton>
                                    </Grid>}
                                </Grid>
                            </TableCellSmall>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
