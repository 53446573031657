export const currencies = ["CAD", "USD", "GBP", "EUR", "HKD", "CNY", "AUD", "JPY", "ZAR", "SGD", "SEK", "DKK", "CHF", "INR", "OMR", "BHD", "QAR", "AED", "ILS", "ISK"]
export const countryCurrencies = {"CA": "CAD", "US": "USD", "UK": "GBP", "GB": "GBP"}
export const month_abbr = ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
export const quarter_abbr = ["Q1", "Q2", "Q3", "Q4"]

export const ACCOUNTING_STRATEGY = "accounting_strategy"
export const STORAGE_STRATEGY = "storage_strategy"
export const CONTRACT_STRATEGY = "contract_strategy"
export const RESOURCING_STRATEGY = "resourcing_strategy"

export const ORG_PLAN_BASIC = "basic"
export const ORG_PLAN_ENTERPRISE = "enterprise"
