import { useDispatch, useSelector } from 'react-redux';

import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { formattedCurrency, getCurrencySymbol } from "@/utils";
import { FormattedCurrency, TableCellSmall, TextFieldNumberSmallAutoHighlight } from "@/common/StyledComponents";
import { selectAllocateTotal, updateLineToAllocateActual, selectLinesToAllocate } from "@/actuals/actualSlice";

function ToAllocateLine({line, projectCurrency, billCurrency}){
    const dispatch = useDispatch()
    const handleInput = (event) => {
        const value = event.target.value.replace(/,/g, '') || 0
        dispatch(updateLineToAllocateActual({lineId: line.id, actual: parseFloat(value)}))
    }
    return(
        <TableRow key={line.id}>
            <TableCellSmall>{line.name}</TableCellSmall>
            <TableCellSmall>{line.section}</TableCellSmall>
            <TableCellSmall>{line.category}</TableCellSmall>
            <TableCellSmall align={"right"}>
                <FormattedCurrency currency={line.vendor_currency} number={line.budget} />
            </TableCellSmall>
            <TableCellSmall align={"right"}>
                <FormattedCurrency currency={projectCurrency} number={line.actual_project} />
            </TableCellSmall>
            <TableCellSmall align={"right"}>
                <TextFieldNumberSmallAutoHighlight 
                    value={line.actual == 0 ? "" : line.actual.toFixed(2).replace(/[.,]00$/, "")}
                    onInput={handleInput}
                    variant="standard" 
                    onWheel={(e)=>e.target.blur()}
                    InputProps={{startAdornment: 
                        <InputAdornment position="start" style={{pointerEvents: "none"}}>
                            <p style={{fontSize: 10, pointerEvents: "none", color: "grey"}}>{getCurrencySymbol(billCurrency)}</p>
                        </InputAdornment>
                    }}
                />
            </TableCellSmall>
        </TableRow>
    )
}

export default function ActualLinesAllocateTable(props){
    const linesToAllocate = useSelector(selectLinesToAllocate)
    const allocateTotal = useSelector(selectAllocateTotal)
    const { projectCurrency, billCurrency } = props

    return (
        <TableContainer>
            <Table sx={{marginBottom: 2}}>
                <TableHead>
                    <TableRow>
                        <TableCellSmall>Item</TableCellSmall>
                        <TableCellSmall>Section</TableCellSmall>
                        <TableCellSmall>Category</TableCellSmall>
                        <TableCellSmall align={"right"}>Vendor Budget</TableCellSmall>
                        <TableCellSmall align={"right"}>Project Actual</TableCellSmall>
                        <TableCellSmall align={"right"}>Portion of Bill</TableCellSmall>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {linesToAllocate.map((line, index)=>
                        <ToAllocateLine 
                            key={index}
                            line={line}
                            projectCurrency={projectCurrency}
                            billCurrency={billCurrency}
                        />
                    )}
                </TableBody>
            </Table>
            <Grid container spacing={3} justifyContent={"right"}>
                <Grid item>Total:</Grid>
                <Grid item>{formattedCurrency(allocateTotal, billCurrency)}</Grid>
            </Grid>
        </TableContainer>
    )
}