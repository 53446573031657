import { useRef, useState, useEffect } from "react"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import { ThemeProvider } from "@mui/material/styles"
import { CircularProgress } from "@mui/material"
import UploadIcon from '@mui/icons-material/Upload'

import { baseTheme, darkTheme } from "@/themes"
import ProfileAvatar from "@/common/ProfileAvatar"
import { AccountSectionRow, AccountSubtitle, AccountInput } from "@/account/StyledComponents"
import { requestSilent } from "@/Api"

export default function OrganizationDialog({ data, setData, open, setError, handleClose }) {
    const themeMode = localStorage.getItem("themeMode") || "light"
    const fileHolder = useRef()
    const [ loading, setLoading ] = useState(false)
    const [ name, setName ] = useState("")
    const [ logo, setLogo ] = useState(null)

    const handleAdd = () => {
        setError("")
        setLoading(true)

        const formData = new FormData()
        formData.append("name", name)
        
        const file = fileHolder.current.files[0]
        if (file) {
            formData.append("file", file, file.name)
        }

        requestSilent.post(`organization`, formData)
        .then(response => {
            setData({...response.data})
            handleClose()
        })
        .catch(error => {
            setError("Failed to create workspace")
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const handleUpdate = () => {
        setError("")
        setLoading(true)

        const formData = new FormData()
        formData.append("name", name)
        
        const file = fileHolder.current.files[0]
        if (file) {
            formData.append("file", file, file.name)
        }

        requestSilent.patch(`organization/${data.id}`, formData).then(response => {
            setData({...response.data})
            handleClose()
        }).catch(error => {
            const msg = Array.isArray(error.response.data.detail) ? error.response.data.detail.map(e => e.msg).join(", ") : error.response.data.detail
            setError(msg || "Failed to save workspace")
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleLogo = () => {
        const file = fileHolder.current.files[0]
        setLogo({url_orig: URL.createObjectURL(file)})
    }

    useEffect(() => {
        if (data) {
            setName(data.name)
            setLogo(data.logo)
        } else {
            setName("")
            setLogo(null)
        }
    }, [data])

    return (
        <ThemeProvider theme={themeMode == "dark" ? darkTheme : baseTheme}>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}   
            >
                <DialogTitle>
                    {`${data ? "Edit" : "Add"} Workspace`}
                </DialogTitle>
                <DialogContent sx={{width: "550px"}}>
                    <AccountSectionRow>
                        <AccountSubtitle>Name</AccountSubtitle>
                        <AccountInput>
                            <TextField
                                value={name || ""}
                                onChange={event => setName(event.target.value)}
                                required
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </AccountInput>
                    </AccountSectionRow>
                    <AccountSectionRow>
                        <AccountSubtitle>Logo</AccountSubtitle>
                        <Grid container justifyContent="flex-end" spacing={4}>
                            <Grid item>
                                <ProfileAvatar image={logo} name={name} size={40} />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => fileHolder.current.click()}><UploadIcon /></IconButton>
                                <input
                                    accept="image/png, image/jpeg, image/webp"
                                    ref={fileHolder}
                                    style={{display: "none"}}
                                    type="file"
                                    onChange={handleLogo}
                                />
                            </Grid>
                        </Grid>
                    </AccountSectionRow>
                </DialogContent>
                <DialogActions>
                    {loading && (
                        <CircularProgress size={24} />
                    )}
                    <Button variant="contained" color="success" disabled={loading} onClick={data ? handleUpdate : handleAdd}>Save</Button>
                    <Button variant="contained" color="secondary" disabled={loading} onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
