import { useSelector } from "react-redux"

import { useTheme } from "@mui/material"
import { Grid } from "@mui/material"
import { Link } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

import { selectIsLoggedIn, selectIsComplete } from "@/auth/authSlice"

export default function Footer(){
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const isComplete = useSelector(selectIsComplete)
    const leftAlign = !isMobile && (!isLoggedIn || !isComplete)

    const style = {
        paddingTop: 30,
        paddingBottom: 50,
        width: "100%",
        textAlign: "center",
        boxSizing: "border-box",
        position: "absolute",
        bottom: 0,
    }
    if (leftAlign) {
        style.width = "50%"
        style.paddingLeft = 30
        style.paddingRight = 30
    }
    
    return (
        <div style={style}>
            <Grid container alignItems={"center"} justifyContent={"center"} marginBottom={1}>
                <Grid item paddingRight={2}>
                    <img width={100} src={`/makers-wordmark-${theme.palette.mode}.svg`} />
                </Grid>
                <Grid item>
                    <img width={115} src={`/central-wordmark-bold-${theme.palette.mode}.svg`} />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                <Grid item>
                    <Link sx={{fontSize: 12, textDecoration: "none"}} href="/terms-of-use">
                        Terms of Use
                    </Link>
                </Grid>
                <Grid item sx={{fontSize: 12, textDecoration: "none"}}>
                    &
                </Grid>
                <Grid item>
                    <Link sx={{fontSize: 12, textDecoration: "none"}} href="/privacy-policy">
                        Privacy Policy
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}
