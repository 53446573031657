import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from 'styled-components'

import { Modal, useTheme, Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import CircularProgress from "@mui/material/CircularProgress"

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close'
import UpdateIcon from '@mui/icons-material/Update'
import DoneIcon from '@mui/icons-material/Done'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { request } from "@/Api"
import BidActionPopup from "@/bid/BidActionPopup"
import ConfirmationDialog from "@/common/ConfirmationDialog"
import { BoldInline } from "@/common/StyledComponents"
import { formattedCurrency } from "@/utils"
import { selectTotals, selectStatus, selectIdenticalOfficeAndClientCurrency, addActivity, selectProject, selectOfficeCurrency, selectClientCurrency, selectFile, addFiles, selectRdBidIds, closeRdPopup, selectName, selectXodoDocumentHash } from "@/bid/bidSlice"
import { selectHasPermission, selectHasRole, selectIsEnterprise, selectIsMakers } from "@/auth/authSlice"
import * as constants from "@/bid/constants"

import { TopSheetConfigurator } from "./TopSheetConfigurator"
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FilesUploader from "@/common/FilesUploader"
import { ACCOUNTING_STRATEGY } from "@/constants"
import StrategyComponents from "@/common/StrategyComponents"
import { downloadTopsheet } from "./TopsheetDownload"

const Detail = styled.p`
    margin: 0;
`
const DetailFaded = styled(Detail)`
    font-size: 10px;
    color: gray;
`

const CustomBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:30%;
    min-width: 500px;
    padding: 24px;
`

export default function BidSum(props){
    const { bidId, canViewConfidentialData, sendSocketMessage, isTemplate } = props
    
    const theme = useTheme()
    const dispatch = useDispatch()

    const [ actionOpen, setActionOpen ] = useState(false)
    const [ actionType, setActionType ] = useState("")
    const [ cancelOpen, setCancelOpen ] = useState(false)
    const [ downloadForSignatureOpen, setDownloadForSignatureOpen ] = useState(false)
    const [ requestFormOpen, setRequestFormOpen ] = useState(false)
    const [ confirmingSigned, setConfirmingSigned ] = useState(false)
    const [ signedOpen, setSignedOpen ] = useState(false)
    const [ topConfigOpen, setTopConfigOpen ] = useState(false)
    const [ filesUploaderOpen, setFilesUploaderOpen ] = useState(false)
    const [ anchorEl, setAnchorEl ] = useState(null);

    const bidName = useSelector(selectName)
    const xodoHash = useSelector(selectXodoDocumentHash)
    const totals = useSelector(selectTotals)
    const status = useSelector(selectStatus)
    const hideOfficeCurrency = useSelector(selectIdenticalOfficeAndClientCurrency) || !useSelector(selectIsMakers)
    const project = useSelector(selectProject)
    const officeCurrency = useSelector(selectOfficeCurrency)
    const clientCurrency = useSelector(selectClientCurrency)
    const file = useSelector(selectFile)
    const rdBidIds = useSelector(selectRdBidIds)
    const canApproveBids = useSelector(state => selectHasPermission(state, "approve_bids"))
    const isFinance = useSelector(state => selectHasRole(state, "finance"))
    const isEnterprise = useSelector(selectIsEnterprise)

    const menuOpen = Boolean(anchorEl);

    const handleFileUploaded = (newFiles) => {
        submitSigned()
        setFilesUploaderOpen(false)
        dispatch(addFiles(newFiles))
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleOpenTopSheetConfig = () => {
        setTopConfigOpen(true)
        handleMenuClose()
    }

    const handleOpenRequestForm = () => {
        handleOpenTopSheetConfig()
        setRequestFormOpen(true)
    }
    
    const handleOpenFilesUploader = () => {
        setFilesUploaderOpen(true)
        handleMenuClose()
    }
    
    const handleAction = (action) => () => {
        setActionType(action)
        setActionOpen(true)
    }

    const handleCancel = () => {
        setCancelOpen(true)
    }
    const submitCancel = () => {
        request.post(`/bids/${bidId}/activity`, {action: constants.BID_ACTIVITY_CANCELLED, note: "Cancelled"})
        .then(response => {
            setCancelOpen(false)
            dispatch(addActivity(response.data))
        })
    }

    const submitDeleteBills = () => {
        for(const id of rdBidIds) {
            request.delete(`/bids/${id}/bills`)
        }
        dispatch(closeRdPopup())
    }

    const submitSigned = () => {
        setConfirmingSigned(true)
        request.post(`/bids/${bidId}/activity`, {action: constants.BID_ACTIVITY_SIGNED, note: "Confirm client signed by uploading existing signature"})
        .then(response => {
            dispatch(addActivity(response.data))
            setConfirmingSigned(false)
        })
    }

    const downloadForSignature = () => {
        setAnchorEl(null)
        request.post(`/bids/${bidId}/activity`, {action: constants.BID_ACTIVITY_DOWNLOADED, note: "Final version of topsheet downloaded for signature, bid sheet locked"})
        .then(response => {
            dispatch(addActivity(response.data))
            downloadTopsheet(bidName)
        })
    }

    const getActionButtons = () => {
        const buttons = [
            (
                <Button
                    sx={{background: "white", color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<TuneRoundedIcon />}
                    onClick={handleOpenTopSheetConfig}
                >
                    Top Sheet
                </Button>
            )
        ]

        if (!isEnterprise){
            if (status == constants.BID_STATUS_DRAFT) {
                buttons.push(<>
                    {confirmingSigned ? 
                    <CircularProgress /> :
                    <Button
                        sx={{color: "black", fontSize:"10px"}}
                        variant="contained"
                        startIcon={ <SendIcon /> }
                        onClick={handleMenuOpen}
                    >
                        Signature
                    </Button>}
                </>)
            }
            if (status == constants.BID_STATUS_PENDING_SIGNATURE) {
                buttons.push(
                    <Button
                    sx={{background: "white", color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<CloseIcon />}
                    onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button
                        sx={{color: "black", fontSize:"10px"}}
                        variant="contained"
                        startIcon={xodoHash ? <ScheduleRoundedIcon /> : <FileUploadIcon />}
                        onClick={handleOpenFilesUploader}
                        disabled={xodoHash}
                    >
                        {xodoHash ? "Pending Signature" : "Upload Signed Topsheet"}
                    </Button>
                )
            }
            if (status == constants.BID_STATUS_SIGNED) {
                buttons.push(
                    <Button
                        color="primary"
                        sx={{color: "black", fontSize:"10px"}}
                        variant="contained"
                        startIcon={<DescriptionOutlinedIcon />}
                        target="_blank"
                        href={file?.url}
                        disabled={!file}
                    >
                        Signed Document
                    </Button>
                )
            }
            return buttons
        }

        if (canViewConfidentialData){
            if (status == constants.BID_STATUS_DRAFT) {
                buttons.push((
                    <Button
                        sx={{color: "black", fontSize:"10px"}}
                        variant="contained"
                        startIcon={<SendIcon />}
                        onClick={handleAction(constants.BID_ACTIVITY_SUBMITTED_EP)}
                    >
                        Send for Approval
                    </Button>
                ))
            }
            else if (status == constants.BID_STATUS_PENDING_EP || status == constants.BID_STATUS_PENDING_FINANCE) {
                if (canApproveBids && (status == constants.BID_STATUS_PENDING_EP || isFinance)) {
                    buttons.push((
                        <Button
                            sx={{color: "black", fontSize:"10px"}}
                            color="error"
                            variant="contained"
                            startIcon={<CloseIcon />}
                            onClick={handleAction(constants.BID_ACTIVITY_REJECTED)}
                        >
                            Reject
                        </Button>
                    ), (
                        <Button
                            sx={{color: "black", fontSize:"10px"}}
                            variant="contained"
                            startIcon={<DoneIcon />}
                            onClick={handleAction(constants.BID_ACTIVITY_APPROVED)}
                        >
                            Approve
                        </Button>
                    ))
                } else {
                    buttons.push((
                        <Button
                            sx={{background: "white", color: "black", fontSize:"10px"}}
                            variant="contained"
                            startIcon={<CloseIcon />}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    ),(
                        <Button
                            sx={{color: "black", fontSize:"10px"}}
                            variant="contained"
                            startIcon={<UpdateIcon />}
                            disabled
                        >
                            Approval
                        </Button>
                    ))
                }
            }
            else if (status == constants.BID_STATUS_APPROVED) {
                buttons.push((
                    <Button
                        sx={{background: "white", color: "black", fontSize:"10px"}}
                        variant="contained"
                        startIcon={<CloseIcon />}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                ),(
                    <>
                        {confirmingSigned ? <CircularProgress /> :
                        <Button
                            sx={{color: "black", fontSize:"10px"}}
                            variant="contained"
                            startIcon={<SendIcon />}
                            onClick={handleMenuOpen}
                        >
                            Signature
                        </Button>}
                    </>
                ))
            }
            else if (status == constants.BID_STATUS_PENDING_SIGNATURE){
                buttons.push((
                    <Button
                        sx={{background: "white", color: "black", fontSize:"10px"}}
                        variant="contained"
                        startIcon={<CloseIcon />}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                ),(
                    <Button
                        startIcon={<ScheduleRoundedIcon />}
                        sx={{color: "black", fontSize:"10px"}}
                        variant="contained"
                        disabled
                    >
                        Pending Signature
                    </Button>
                ))
            }
            else if (status == constants.BID_STATUS_SIGNED) {
                buttons.push((
                    <Button
                        color="primary"
                        sx={{color: "black", fontSize:"10px"}}
                        variant="contained"
                        startIcon={<DescriptionOutlinedIcon />}
                        target="_blank"
                        href={file?.url}
                        disabled={!file}
                    >
                        Signed Document
                    </Button>
                ))
            }
        }

        return buttons
    }

    if (isTemplate == undefined){
        return
    }

    if (isTemplate == true){
        return(
            <BidSumContainer canViewConfidentialData={canViewConfidentialData}>
                <TopSheetConfigurator 
                    sendSocketMessage={sendSocketMessage} 
                    open={topConfigOpen} 
                    close={()=>{setTopConfigOpen(false)}}
                    requestFormOpen={requestFormOpen}
                    setRequestFormOpen={setRequestFormOpen}
                />
                <Button
                    sx={{background: "white", color: "black", fontSize:"10px"}}
                    variant="contained"
                    startIcon={<TuneRoundedIcon />}
                    onClick={handleOpenTopSheetConfig}
                >
                    Top Sheet
                </Button>
            </BidSumContainer>
        )
    }

    return (
        <BidSumContainer canViewConfidentialData={canViewConfidentialData}>
            <TopSheetConfigurator 
                sendSocketMessage={sendSocketMessage} 
                open={topConfigOpen} 
                close={()=>{setTopConfigOpen(false)}}
                requestFormOpen={requestFormOpen}
                setRequestFormOpen={setRequestFormOpen}
            />
            <Modal open={filesUploaderOpen} onClose={()=>setFilesUploaderOpen(false)}>
                <CustomBox sx={{background: theme.palette.background.paper, borderRadius: 3}}>
                    <FilesUploader files={[]} modelId={bidId} modelType={"bid"} onUploadedCallback={handleFileUploaded}/>
                </CustomBox>
            </Modal>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid container item alignItems="center" justifyContent={canViewConfidentialData ? "space-between" : "center"} md={canViewConfidentialData ? 7 : 5}>
                    <Grid item>
                        <Detail>Bid Quoted</Detail>
                        <Detail>{formattedCurrency(totals.client_quoted, clientCurrency)}</Detail>
                        {!hideOfficeCurrency ? <DetailFaded>{formattedCurrency(totals.office_quoted, officeCurrency)}</DetailFaded> : null}
                    </Grid>
                    {canViewConfidentialData ? <>
                        <Grid item>
                            <Detail>Budget</Detail>
                            <Detail>{formattedCurrency(totals.client_budget, clientCurrency)}</Detail>
                            {!hideOfficeCurrency ? <DetailFaded>{formattedCurrency(totals.office_budget, officeCurrency)}</DetailFaded> : null}
                        </Grid>
                        <Grid item>
                            <Detail>Marked Up Fee</Detail>
                            <Detail>{formattedCurrency(totals.client_marked_up_fee, clientCurrency)}</Detail>
                            {!hideOfficeCurrency ? <DetailFaded>{formattedCurrency(totals.office_marked_up_fee, officeCurrency)}</DetailFaded> : null}
                        </Grid>
                        <Grid item>
                            <Detail>Adjustment</Detail>
                            <Detail>{formattedCurrency(totals.adjustment, clientCurrency)}</Detail>
                            {!hideOfficeCurrency ? <DetailFaded>{formattedCurrency(totals.office_adjustment, officeCurrency)}</DetailFaded> : null}
                        </Grid>
                        <Grid item>
                            <Detail>Gross Profit</Detail>
                            <Detail>{formattedCurrency(totals.client_gross_profit, clientCurrency)}</Detail>
                            {!hideOfficeCurrency ? <DetailFaded>{formattedCurrency(totals.office_gross_profit, officeCurrency)}</DetailFaded> : null}
                        </Grid>
                        <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                            <Grid item>
                                <Detail>Net Profit</Detail>
                                <Detail>{formattedCurrency(totals.client_net_profit, clientCurrency)}</Detail>
                                {!hideOfficeCurrency ? <DetailFaded>{formattedCurrency(totals.office_net_profit, officeCurrency)}</DetailFaded> : null}
                            </Grid>
                        </StrategyComponents>
                    </> : null}
                </Grid>

                <Divider orientation="vertical" flexItem md={canViewConfidentialData ? 1 : 2} />
            
                <Grid container item alignItems="space-between" md={canViewConfidentialData ? 4 : 5}>
                    {getActionButtons().map((button, i) => (
                        <Grid item flexGrow={1} key={i} sx={{textAlign: "center"}}>
                            {button}
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <BidActionPopup
                bidId={bidId}
                action={actionType}
                isFinance={isFinance}
                open={actionOpen}
                setOpen={setActionOpen}
            />

            <ConfirmationDialog
                open={rdBidIds.length > 0}
                closeDialog={() => dispatch(closeRdPopup())}
                title={`Delete R&D bills?`}
                callback={submitDeleteBills}
            >
                <p>This project has existing bills from an R&D bid sheet. If those bills are no longer valid, would you like to delete them?</p>
            </ConfirmationDialog>

            <ConfirmationDialog
                open={downloadForSignatureOpen}
                closeDialog={() => setDownloadForSignatureOpen(false)}
                title={`Download topsheet for signature`}
                callback={downloadForSignature}
            >
                <p>You are about to download and lock the final version of your bid sheet.</p>
            </ConfirmationDialog>

            <ConfirmationDialog
                open={cancelOpen}
                closeDialog={() => setCancelOpen(false)}
                title={`Are you sure?`}
                callback={submitCancel}
            >
                <p>This will revert to a draft and require re-approval.</p>
            </ConfirmationDialog>

            <ConfirmationDialog
                open={signedOpen}
                closeDialog={() => setSignedOpen(false)}
                title="Heads up..."
                callback={submitSigned}
            >
                Marking this bid as signed will automatically change the status of<br />
                <BoldInline>{ project.name }</BoldInline> to <BoldInline>In Production</BoldInline>.
            </ConfirmationDialog>

            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                anchorOrigin={{horizontal: "right", vertical: "top"}}
                transformOrigin={{horizontal: "right", vertical: "bottom"}}
                onClose={handleMenuClose}
            >  
                <MenuItem
                    onClick={handleOpenRequestForm}
                >
                    Request client signature
                </MenuItem>
                {isEnterprise && 
                <MenuItem
                    onClick={handleOpenFilesUploader}
                >
                    Upload signed topsheet
                </MenuItem>}
                {!isEnterprise &&
                <MenuItem
                    onClick={()=>{setDownloadForSignatureOpen(true)}}
                >
                    Download for Signature
                </MenuItem>}
            </Menu>
        </BidSumContainer>
    )
}

export function BidSumMobile(){
    const officeCurrency = useSelector(selectOfficeCurrency)
    const clientCurrency = useSelector(selectClientCurrency)
}

const BidSumContainer = (props) => {
    const {children, canViewConfidentialData} = props
    const theme = useTheme()
    return(
        <div style={{
            width: canViewConfidentialData ? "auto" : "380px",
            margin: "auto",
            zIndex: 1,
            fontSize: "12px",
            background: theme.palette.background.paper,
            position: "sticky",
            bottom: 30,
            padding: 20,
            paddingLeft: 40,
            paddingRight: 40,
            borderRadius: 100,
            boxShadow: "0px 0px 50px 0px #00000040"
        }}>
            {children}
        </div>
    )
}
