import { useEffect, useState } from "react";
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { request } from "@/Api";
import { formattedCurrency } from "@/utils";
import { CustomBox, SearchBox } from "@/common/StyledComponents"
import ActualLinesAssignTable from "@/actuals/ActualLinesAssignTable";
import ActualLinesAllocateTable from "@/actuals/ActualLinesAllocateTable";
import { selectWorkingBill, selectLinesToAllocate, selectAllocateTotal, cancelAssignBill, selectIsAllocating, allocate, setLinesToAllocate, distributeRemaining, selectAllocateLineCount, selectSearchLineIds, selectLines} from "@/actuals/actualSlice";

const SmallGrey = styled.span`
    color: grey;
    font-size: 11px;
`
 
export default function ActualizationPopup({getAndSetProject, projectCurrency}){
    const theme = useTheme()
    const dispatch = useDispatch()

    const accountingBill = useSelector(selectWorkingBill)
    const actualLines = useSelector(selectLines)
    const [ searchValue, setSearchValue ] = useState("")
    const lineIds = useSelector(state => selectSearchLineIds(state, searchValue))

    useEffect(()=>{
        if (accountingBill.open){
            resetLinesToAllocate()
        }
    }, [accountingBill])

    const handleClose = () => {
        dispatch(cancelAssignBill())
        dispatch(allocate(false))
        dispatch(setLinesToAllocate({}))
    }

    const resetLinesToAllocate = () => {
        let assignedActualLines = [...accountingBill.actual_lines]
        for (let i in assignedActualLines){
            const actualLine = Object.values(actualLines).find(line => assignedActualLines[i].actual_line_id == line.id)
            assignedActualLines[i] = {...assignedActualLines[i], ...actualLine}
        }
        dispatch(setLinesToAllocate(assignedActualLines))
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value.toLowerCase())
    }
    return (
        <Modal
            onClose={handleClose}
            open={accountingBill.open}
        >
            <CustomBox
                sx={{ 
                    background: theme.palette.background.default, 
                    overflow: "scroll" 
                }}
            >
                <Grid container alignItems={"center"} justifyContent={"space-between"} paddingBottom={2}>
                    <Grid item>
                        <Grid container spacing={1} alignItems={"center"}>
                            <Grid item><span>Assign bill</span></Grid>
                            <Grid item><span>{`#${accountingBill.accounting_bill_number}`}</span></Grid>
                            <Grid item><SmallGrey>{`${accountingBill.vendor_name} |`}</SmallGrey></Grid>
                            <Grid item><SmallGrey>{formattedCurrency(accountingBill.actual, accountingBill.currency)}</SmallGrey></Grid>
                        </Grid>
                    </Grid>
                    <AllocatingItemWrapper>
                        <Grid item>
                            <Tooltip title="Distribute remaining to blanks">
                                <IconButton onClick={()=>{dispatch(distributeRemaining())}}>
                                    <AutoFixHighIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </AllocatingItemWrapper>
                </Grid>

                <AssigningItemWrapper>
                    <div style={{marginBottom: 20}}>
                        <SearchBox handleSearch={handleSearch}/>
                    </div>
                </AssigningItemWrapper>

                <div style={{height: 450, overflow: "scroll", marginBottom: 20}}>
                    <AllocatingItemWrapper>
                        <ActualLinesAllocateTable
                            projectCurrency={projectCurrency}
                            billCurrency={accountingBill?.currency}
                        />
                    </AllocatingItemWrapper>
                    <AssigningItemWrapper>
                        <ActualLinesAssignTable
                            lineIds={lineIds}
                            projectCurrency={projectCurrency}
                        />
                    </AssigningItemWrapper>
                </div>

                <ActionButtons 
                    handleClose={handleClose}
                    getAndSetProject={getAndSetProject}
                />
            </CustomBox>
        </Modal>
    )
}

function AssigningItemWrapper({children}){
    const isCurrentlyAllocating = useSelector(selectIsAllocating) 
    return (
        <div hidden={isCurrentlyAllocating == true}>
            { children }
        </div>
    )
}
function AllocatingItemWrapper({children}){
    const isCurrentlyAllocating = useSelector(selectIsAllocating)
    return (
        <div hidden={isCurrentlyAllocating == false}>
            { children }
        </div>
    )
}
function ActionButtons({handleClose, getAndSetProject}){
    const dispatch = useDispatch()
    const linesToAllocate = useSelector(selectLinesToAllocate)
    const accountingBill = useSelector(selectWorkingBill)
    const allocateTotal = useSelector(selectAllocateTotal)
    const allocateLineCount = useSelector(selectAllocateLineCount)

    const confirmAllocation = (linesToAllocate) => {
        request.put(`accounting-bills/${accountingBill.id}/assign`, linesToAllocate)
        .then(()=>{
            getAndSetProject()
            handleClose()
        })
    }

    const handleAllocate = () => {
        if (linesToAllocate.length == 1){
            const line = [{...linesToAllocate[0], actual: accountingBill.actual}]
            confirmAllocation(line)
        }else{
            dispatch(allocate(true))
        }
    }
    return (
        <div>
            <AssigningItemWrapper>
                <Grid container spacing={2} justifyContent={"right"}>
                    <Grid item><Button variant="outlined" onClick={handleClose}>Cancel</Button></Grid>
                    {allocateLineCount > 0 ?
                    <Grid item>
                        <Button variant="contained" onClick={handleAllocate}>
                            {allocateLineCount > 1 ? `Start allocacting to ${allocateLineCount} items` : "Fully allocate"}
                        </Button>
                    </Grid> : null}
                </Grid>
            </AssigningItemWrapper>

            <AllocatingItemWrapper>
                <Grid container spacing={2} justifyContent={"right"}>
                    <Grid item><Button variant="outlined" onClick={handleClose}>Cancel</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={()=>dispatch(allocate(false))}>Back to Assigning</Button></Grid>
                    <Grid item>
                        <Button disabled={allocateTotal != accountingBill.actual} variant="contained" onClick={()=>{confirmAllocation(linesToAllocate)}}>
                            {
                                allocateTotal < accountingBill.actual ? `${formattedCurrency(accountingBill.actual - allocateTotal, accountingBill.currency)} left to allocate` :
                                allocateTotal > accountingBill.actual ? `${formattedCurrency(-(accountingBill.actual - allocateTotal), accountingBill.currency)} over allocated` :
                                "Confirm Allocation"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </AllocatingItemWrapper>
        </div>
    )
}