import {  useSelector } from 'react-redux';

import { FlexTable, FlexRow, FlexTitleCell } from "@/common/StyledComponents";
import ActualLineRow from "@/actuals/ActualLineRow";
import { selectSearchLineIds } from '@/actuals/actualSlice'

export default function ActualLinesTable({searchValue, projectCurrency, getProject}){
    const lineIds = useSelector(state => selectSearchLineIds(state, searchValue))

    return (
        <FlexTable>
            <FlexRow padding="0 0 16px 0">
                <FlexTitleCell>Name</FlexTitleCell>
                <FlexTitleCell>Section</FlexTitleCell>
                <FlexTitleCell>Category</FlexTitleCell>
                <FlexTitleCell grow={2} minWidth={400}>Note</FlexTitleCell>
                <FlexTitleCell align={"right"} padding="0 12px 0 0">Vendor Budget</FlexTitleCell>
                <FlexTitleCell align={"right"} minWidth={90}>Vendor Running</FlexTitleCell>
                <FlexTitleCell align={"right"}>Vendor Actual</FlexTitleCell>
                <FlexTitleCell align={"right"}>Project Actual</FlexTitleCell>
            </FlexRow>
            {lineIds.map((actualLineId) =>
                <ActualLineRow 
                    key={actualLineId} 
                    actualLineId={actualLineId} 
                    projectCurrency={projectCurrency}
                    getProject={getProject}
                />
            )}
        </FlexTable>
    )
}
