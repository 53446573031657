import 'draft-js/dist/Draft.css';

import { request } from "@/Api";
import { Heading } from "@/common/StyledComponents";
import { Button, Grid, Link, useTheme, IconButton, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useState } from "react";
import { selectSchema, setToken } from "@/auth/authSlice"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw, Modifier } from "draft-js";
import { useRef } from "react";

import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatIndentIncreaseRoundedIcon from '@mui/icons-material/FormatIndentIncreaseRounded';
import FormatIndentDecreaseRoundedIcon from '@mui/icons-material/FormatIndentDecreaseRounded';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import { API_BASE_URL } from '@/config';

export default function SuperAdmin(){
    const [orgs, setOrgs] = useState([])
    const getOrgs = () => {
        request.get("admin/organizations")
        .then(response=>{
            setOrgs(response.data)
        })
    }
    const goToDoc = () => {
        window.location = `${API_BASE_URL}/docs`
    }
    useEffect(()=>{
        getOrgs()
    }, [])
    return (
        <>
            <div style={{marginBottom: 50}}>
                <Heading>⚠️ SUPER ADMIN PANEL ⚠️</Heading>
                <span style={{color: "gray"}}>With great power comes great responsibility</span>
            </div>

            <div style={{marginBottom: 50}}>
                <h3>Docs</h3>
                <Button variant="contained" onClick={goToDoc}>Open API Doc</Button>
            </div>

            <div style={{marginBottom: 50}}>
                <h3>Gloabl Template Organization</h3>
                <OrgGridItem org={orgs.find(org=>org.schema == "organization")}/>
            </div>

            <div style={{marginBottom: 50}}>
                <h3>Organizations</h3>
                <Grid container spacing={2}>
                    {orgs.map(org=>
                        <OrgGridItem key={org.id} org={org}/>
                    )}
                </Grid>
            </div>

            <RichTextEditorSection name={"Terms of Use"}/>
            <RichTextEditorSection name={"Privacy Policy"}/>
        </>
    )
}

const OrgGridItem = ({org}) => {
    if (!org) {
        return
    }
    const isCurrentSchema = useSelector(selectSchema) == org.schema
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch()
    const loginTo = () => {
        request.get(`login-to/${org.id}`)
        .then(response=>{
            dispatch(setToken(response.data))
            navigate("/projects")
        })
    }
    return (
        <Grid item md={3} xs={6}>
            <div 
                style={{
                    background: theme.palette.background.paper, 
                    borderRadius: 10, padding: 10, border: isCurrentSchema ? `2px solid ${theme.palette.primary.main}`: null
                }}
            >
                <p>{`name: ${org.name}`}</p>
                <p>{`id: ${org.id}`}</p>
                <p>{`schema: ${org.schema}`}</p>
                <Button variant="contained" onClick={loginTo}>Go!</Button>
            </div>
        </Grid>
    )
}

const RichTextEditorSection = ({name}) => {
    const theme = useTheme()
    const editor = useRef(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const getTerm = () => {
        request.get(`public-terms/${name}`)
        .then(response=>{
            const contentStateString = response.data.draft_js_raw_state_string
            if (contentStateString){
                setEditorState(deserializeContentState(contentStateString))
            }
        })
        .catch(error=>{
            console.log(error)
        })
    }
    const focusEditor = () => {
        editor.current.focus();
    }
    const handleChange = (es) => {
        setEditorState(es)
    }
    const serializedEditorState = () => {
        const contentState = convertToRaw(editorState.getCurrentContent())
        const contentStateString = JSON.stringify(contentState)
        return contentStateString
    }
    const deserializeContentState = (contentStateString) => {
        const contentStateObject = JSON.parse(contentStateString)
        const contentState = convertFromRaw(contentStateObject)
        const editorState = EditorState.createWithContent(contentState)
        return editorState
    }
    const handlePublish = () => {
        request.put(`public-terms/${name}`, {
            name: name, 
            draft_js_raw_state_string: serializedEditorState()
        })
        .catch(error=>{
            console.log(error)
        })
    }
    const onInlineStyleClick = (style) => {
        handleChange(RichUtils.toggleInlineStyle(editorState, style));
    }
    const onInsertSoftLine = () => {
        const newState = RichUtils.insertSoftNewline(editorState);
        setEditorState(newState);
    }
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }
    const adjustDepth = (newDepth) => {
        const newState = RichUtils.onTab(
            new Event('keydown'),
            editorState,
            newDepth
        );
        setEditorState(newState);
    }
    useEffect(()=>{
        getTerm()
    }, [])
    return (
        <Accordion sx={{background: "none"}}>
            <AccordionSummary><h3>{name}</h3></AccordionSummary>
            <AccordionDetails>
                <div style={{marginBottom: 50, position: "relative", borderRadius: 20, border: "1px solid grey", padding: 20}}>
                    <Grid 
                        sx={{background: theme.palette.background.default}}
                        container justifyContent={"space-between"}  alignItems={"center"} 
                        position={"sticky"} top={0} zIndex={2}
                    >
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <Grid item paddingRight={3}>
                                    <h3>{name}</h3>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={(e)=>{onInlineStyleClick("BOLD")}}>
                                        <FormatBoldRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{onInsertSoftLine()}}>
                                        <KeyboardReturnRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{toggleBlockType('ordered-list-item')}}>
                                        <FormatListNumberedRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{toggleBlockType('unordered-list-item')}}>
                                        <FormatListBulletedRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{adjustDepth(4)}}>
                                        <FormatIndentIncreaseRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{adjustDepth(-1)}}>
                                        <FormatIndentDecreaseRoundedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button onClick={handlePublish}>Publish Changes</Button>
                        </Grid>
                    </Grid>
                    <div
                        style={{ minHeight: "6em", cursor: "text" }}
                        onClick={focusEditor}
                    >
                        <div style={{lineHeight: 2}}>
                            <Editor
                                ref={editor}
                                editorState={editorState}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    
    )
}